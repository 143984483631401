jQuery( document ).ready(function($) {

  $('.menu-trigger').click(function(e){
    e.preventDefault()
    $('.sidebar').toggleClass('active')
    if($(this).html() === 'Menu'){
      $(this).html('Close')
    } else{
      $(this).html('Menu')
    }
  })


  // Stick when top
  const stickThis = document.querySelector(".sidebar");
  const startPosition = stickThis.getBoundingClientRect().top + window.pageYOffset;
  window.onscroll = function() {
    if (window.pageYOffset > startPosition) {
      stickThis.classList.add('sticky');
    } else {
      stickThis.classList.remove('sticky');
    }
  }

});